/* 
---------------------------------------------------------------
CustomTabs
--------------------------------------------------------------- 
*/
.CustomTabs {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.CustomTabs .tab-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.CustomTabs .result-count {
  color: var(--color-dark-grey);
  font-size: 0.9em;
  margin: 15px 0 10px 15px;
}

.CustomTabs .tab-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
  padding: 1vw 0 0 0;
  background: var(--color-light-grey);
  border-bottom: 1px solid var(--color-medium-grey);
}

.CustomTabs.left .tab-list {
  justify-content: flex-start;
  flex-wrap: wrap;
}

.CustomTabs .tab-list-item {
  margin: 0 0 0 0;
  color: var(--color-black);
  font-family: 'Rubik-Regular';
  font-size: 0.8em;
  padding: 7px 5px;  
  cursor: pointer;
}

.CustomTabs .tab-list-item:hover .label,
.CustomTabs .tab-list-item:focus .label {
  color: var(--color-black);
}
.CustomTabs .tab-list-item:nth-child(n+2) {
  margin: 0 0 0 20px;
}
.CustomTabs .tab-list-item.active,
.CustomTabs .tab-list-item:hover,
.CustomTabs .tab-list-item:focus,
.CustomTabs .tab-list-item.active.disabled:hover,
.CustomTabs .tab-list-item.active.disabled:focus {
  border-bottom: 2px solid var(--color-black);
}
.CustomTabs .tab-list-item.active .label,
.CustomTabs .tab-list-item.has-results .label {
  color: var(--color-black);
}
.CustomTabs .tab-list-item .label {
  text-transform: uppercase; 
  font-family: 'Rubik-Medium';
  margin: 0 0 0 0 ;
  white-space: nowrap;
}
.CustomTabs .tab-list-item .label-result-count {
  margin: 0 0 0 5px;
  color: var(--color-dark-grey);
}

.CustomTabs .tab-list-item.disabled,
.CustomTabs .tab-list-item.active.disabled .label,
.CustomTabs .tab-list-item.disabled:hover .label,
.CustomTabs .tab-list-item.disabled:focus .label {
  cursor: default;
  color: var(--color-dark-grey) !important;
  background: none !important;
}

.CustomTabs .tab-list-item.disabled:hover,
.CustomTabs .tab-list-item.disabled:focus {
  border-bottom: none;
}


/* Slim appearance */
.CustomTabs.slim .tab-list {
  background: transparent;
  padding: 0;
}

/* Buttons appearance */
.CustomTabs.buttons .tab-list {
  border: none;
}

.CustomTabs.buttons .tab-list-item {
  color: var(--color-dark-grey);
  padding: 7px 13px;  
  border-radius: 100px;
}
.CustomTabs.buttons .tab-list-item .label {
  color: var(--color-dark-grey);
  font-size: 0.9em;
}

.CustomTabs.buttons .tab-list-item:nth-child(n+2) {
  margin: 0 0 0 3px;
}
.CustomTabs.buttons .tab-list-item:hover ,
.CustomTabs.buttons .tab-list-item:focus {
  border-bottom: none;
  background: #E0DAD499;
}
.CustomTabs.buttons .tab-list-item.active.disabled:hover,
.CustomTabs.buttons .tab-list-item.active.disabled:focus {
  border-bottom: none;
}

.CustomTabs.buttons .tab-list-item:hover .label ,
.CustomTabs.buttons .tab-list-item:focus .label {
  color: var(--color-black);
}
.CustomTabs.buttons .tab-list-item.has-results .label {
  color: var(--color-black);
}
.CustomTabs.buttons .tab-list-item.active {
  background: var(--color-black);
  border-bottom: none;
}
.CustomTabs.buttons .tab-list-item.active .label {
  color: #fff;
}

.CustomTabs .tab-content {
  margin: 10px auto 0 auto;
  display:  flex;
  flex-direction: column;
  position: absolute;
  top: 0px; bottom: 0; left: 0; right: 0;
  
}

.tab-content-empty-text {
  margin: 10px 0px;
  font-style: italic;
  color: var(--color-dark-grey);
}

.CustomTabs .tab-content-wrapper {
  margin: 0px auto 0 auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 2;
  overflow-y: auto;
  position:  relative;
  margin-bottom:  10px;
}

.CustomTabs .tab-content-wrapper .tab-content-wrapper {
  margin-bottom:  0px;
}

.CustomTabs.vw-60 .tab-content {
  max-width: 60vw;
}
.CustomTabs.vw-20,
.CustomTabs.vw-20 .tab-content {
  width: 300px;
}



.CustomTabs.show-result-count .tab-content {
  margin: 0 auto;
}


.CustomTabs .search-result {
  padding: 10px 15px 15px 15px;
  border-radius: 3px;
}
.CustomTabs .search-result:hover,
.CustomTabs .search-result-link:focus .search-result {
  background: #fff;
}
.CustomTabs .search-result:active {
  transform: scaleX(0.99) scaleY(0.95);
}


.CustomTabs .search-result-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Rubik-Medium';
  margin: 0 0 0.25vw 0;
}

.CustomTabs .search-result-title .icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 15px 0 0;
}

.CustomTabs .search-result-body {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.CustomTabs .search-result-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0.25vw 0;

}

.CustomTabs .search-result-subtitle .connection_string {
  font-size: 0.9em;
  flex-grow: 2;
  margin: 0 0 0 0;
  color: var(--color-dark-grey);
  display: flex;
}

.color-medium-green {
  color: var(--color-intense-green); /* green */
}
.color-medium-red {
  color: var(--color-intense-red); /* red */
}