/* 
---------------------------------------------------------------
Section
--------------------------------------------------------------- 
*/
.Section {
 border-bottom: 1px solid var(--color-medium-grey);
}

.Section.expanded {

}

.Section .section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  text-transform: uppercase;
  font-family: 'Rubik-Medium';
  font-size: 0.8em;
}

.Section.expanded .section-header {
  padding-bottom: 0px;
}

.Section .section-body {
  display: none;
  padding: 0px 10px 10px 30px;
}

.Section .DataTable {
  position: initial;
}

.Section.expanded .section-body {
  display: block;
}

.Section .section-toggle {
  padding: 0;
  width: 30px;
  max-width: 30px;
}

.Section .button-section-toggle {
  transform: rotate(135deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 30px;
  height: 30px; 
  margin: 0 3px 0 0;
  cursor: pointer;
  transition: transform 0.1s ease-out;
  border-radius: 50%;
}

.Section.expanded .button-section-toggle {
  transform: rotate(225deg);
}

.Section .button-section-toggle::after,
.Section .button-section-toggle::before {
  content: '';
  background: var(--color-dark-grey);
  position: absolute;
  border-radius: 2px;
  left: 0px;
  top: 0px;
  transition: background 0.1s ease-out;
}
.Section .button-section-toggle::after {
  width: 8px;
  height: 2px;
  left: 12px;
  top: 12px;
}
.Section .button-section-toggle::before {
  width: 2px;
  height: 8px;
  left: 12px;
  top: 12px;
}
.Section .button-section-toggle:hover::after,
.Section .button-section-toggle:hover::before {
  background: var(--color-black);
}