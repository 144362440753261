.token.comment {
	/*color: hsl(230, 4%, 64%);*/
	color: var(--color-dark-grey);
	font-style: italic;
}

.token.punctuation {
	/*color: hsl(230, 8%, 24%);*/
	color: #222020AA;
	font-family: 'JetBrainsMono-Medium', monospace;
}


.token.boolean,
.token.constant,
.token.number {
	color: #BA7500;
}

.token.keyword {
	color: #BE48FF;
}

.token.string {
	/*color: hsl(119, 34%, 47%);*/
	color: #00B779;
}

.token.variable,
.token.operator,
.token.function {
	/*color: hsl(221, 87%, 60%);*/
	color: #40A4FF;
}