/* 
---------------------------------------------------------------
CustomInput
--------------------------------------------------------------- 
*/
.CustomInput {
  display: flex;
  flex-direction: column;
  /*overflow: hidden;*/
  position: relative;
}

.CustomInput .input-container-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  border: solid 1px var(--color-light-grey);
  border-radius: 3px;
  background: #fff;
  height: 35px;
}

.CustomInput .input-container {
  position: relative;
  width: 100%;
}
.CustomInput .input {
  max-width: 400px;
  /*border: solid 1px var(--color-light-grey);*/
  height: 33px;
  border: none;
  padding: 0px 15px 0px 15px;
  border-radius: 3px;
  width: 100%;
  transition: width 0.1s ease-out;
}

.CustomInput .input-container-wrapper:focus,
.CustomInput .input-container-wrapper:focus-within,
.CustomInput .input-container-wrapper:hover  {
  border: solid 1px var(--color-black);
}

.CustomInput .input:disabled {
  color: var(--color-dark-grey);
}

.CustomInput.large .input-container-wrapper {
  height: initial;
}

.CustomInput.large .input {
  padding: 10px;
  border: none;
  border-radius: 0;
}

.CustomInput.large {
  margin: 0 0 2vh 0;
}

.CustomInput label {
  margin-bottom: 10px;
  display:  flex;
}

.CustomInput.disabled label {
  color: var(--color-dark-grey);
}

.CustomInput.disabled .input-container-wrapper:hover {
  border: solid 1px var(--color-light-grey);
}

.CustomInput.large.disabled .input-container-wrapper:hover {
  border: none;
}

.CustomInput.label-position-left {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.CustomInput.label-position-left label {
  margin:  0 10px 0 0;
  min-width: 100px;
}
.CustomInput.label-position-left .input-container {
  display: flex;
  flex-grow: 1;
}

label .icon-description {
  width: 13px;
  height:  13px;
  font-size: 0.7em;
  font-family: Rubik-Bold;
  color: var(--color-dark-grey);
  display:  flex;
  justify-content: center;
  align-items: center;
  border:  1px solid var(--color-dark-grey);
  border-radius: 10px;
  margin:  0 0 0 10px;
}

.CustomInput.large .input {
  font-size: 1.5em;
  height: initial;
  border-bottom: 1px solid var(--color-medium-grey);
  background: none;
}

.CustomInput.label-as-placeholder .input {
  padding-top: 20px !important;
}

.CustomInput.label-as-placeholder label {
  padding: 0 10px;
  text-align: left;
  white-space: nowrap;
  font-size: 1.5em;
  color: var(--color-dark-grey);
  pointer-events: none;
  position: absolute;
  transform: translate(0, 20px);
  transform-origin: top left;
  transition: all 0.1s ease-out;
}

.CustomInput.label-as-placeholder:focus-within label,
.CustomInput.label-as-placeholder .active {
  transform: translate(0, 0vw);
  font-size:  0.8em;
}

.CustomInput.large .input-container-wrapper {
  border: none;
}

.CustomInput.large .input:focus,
.CustomInput.large .input:hover {
  border-bottom: 1px solid var(--color-black);
}

.CustomInput.large .input:disabled  {
  color: var(--color-dark-grey);
  background: none;
}

.CustomInput.large .input:disabled:hover {
   border: none;
   border-bottom: 1px solid var(--color-medium-grey);
}

.CustomInput.has-error .input  {
  border-bottom: 1px solid var(--color-intense-red) !important;
}

.CustomInput.large .validation-icon {
  background-repeat: no-repeat;
  background-position: center;
  height: 20px;
}
.CustomInput.large .validation-icon.valid {
  background: url('../../assets/img/icons/completed.svg');
}
.CustomInput.large .validation-icon.error {
  background: url('../../assets/img/icons/failed.svg');
}

.CustomInput .button-clear-input { 
  cursor: pointer;
}

.CustomInput .button-clear-input:hover .button-clear-input-icon::after,
.CustomInput .button-clear-input:hover .button-clear-input-icon::before {
  background: var(--color-black);
}

.CustomInput .button-clear-input .button-clear-input-icon {
  transform: rotate(45deg);
  display: flex;
  align-items: center;
}

.CustomInput .button-clear-input .button-clear-input-icon::before {
  width: 12px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.CustomInput .button-clear-input .button-clear-input-icon::after {
  width: 2px;
  height: 12px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: calc(-50%);
  top: 0;
  display: block;
}

.CustomInput .input-icon {
  position: absolute;
  top: 0;
  left: 0px;
  width: 33px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CustomInput.with-icon .input {
  padding-left: 35px;
}

.CustomInput.collapsed .input-container-wrapper {
  width: 35px;
  overflow: hidden;
}
.CustomInput.collapsed .input {
  width: 33px;
  padding-right: 0;
  padding-left: 33px;
}

.CustomInput.collapsed .input-icon {
  cursor: pointer;
}

.CustomInput .button-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  right: 0;
  top: 0;
  height: 100%;
  margin-right: 5px;
}

.CustomInput.large .button-container {
  margin-top: 6px;
}

.CustomInput .input-button {
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CustomInput .input-button:nth-child(n+2) {
  margin-left: 5px;
}

.CustomInput .button-show-password {
  cursor: pointer;
}

.CustomInput .button-show-password:hover path {
  fill: #222020 !important;
}

.CustomInput .icon-toggle-visibility .hide-icon {
  display: none;
}
.CustomInput.show-password .icon-toggle-visibility .show-icon {
  display: none;
}
.CustomInput.show-password .icon-toggle-visibility .hide-icon {
  display: block !important;
}

.CustomInput .select-buttons {
  /*overflow: initial;*/
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5px 0 0px;
  transition: width 1s linear 1s;
  height: initial;
  
}

.CustomInput .select-buttons:before {
  content: "";
  height: 25px;
  width: 1px;
  margin: 0 5px 0 0px;
  background: var(--color-light-grey);
  display: block;
}

.CustomInput.collapsed .select-buttons {
  /*overflow: hidden;
  
  width: 0;
  display: none;*/
  display: none;
}

/* 
---------------------------------------------------------------
CustomInputSelect
--------------------------------------------------------------- 
*/

.CustomInputSelect {
}

.CustomInputSelect .select-container {
  position: relative;
  border-radius: 3px;
  height: 25px;
  min-width: 100px;
}

.CustomInputSelect .select-input-container {
  margin: 0;
  height: 25px;
}

.CustomInputSelect .select-input-container {
  position: absolute;
  width: 100%;
}

.CustomInputSelect .select-value-container.disabled .select-value-label {
  color: var(--color-dark-grey);
}


.CustomInputSelect .select-value-container { 
  padding: 0 25px 0 5px;
  font-size: 0.9em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
  width: calc(100%);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Rubik-Regular;
}

.CustomInputSelect.all-selected .select-value-container { 
  color: var(--color-dark-grey);
  font-family: Rubik-Light;
}

.CustomInputSelect .select-value { 
  display: flex;
  flex-direction: row;
  align-items: center;
}
.CustomInputSelect .select-value-label { 
  margin: 0;
  cursor: default;
}


.CustomInputSelect .select-button-container {
  position: absolute; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  right: 1px;
  top: 1px; /*calc(50% - (1.2em + 15px)/2);*/
}
.CustomInputSelect .select-button {
  display: flex;
  justify-content: center;
  align-items: center;
  content: '';
  width: 23px;
  height: 23px;
  /*background-color: var(--color-very-light-grey);*/
  border-radius: 3px;
}

.CustomInputSelect .select-input-container:hover .button-toggle-menu,
.CustomInputSelect .select-container.show .button-toggle-menu {
  /*background-color: #fff;*/
}

.CustomInputSelect .button-toggle-menu .button-toggle-menu-icon {
  transform: rotate(-135deg);
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.CustomInputSelect .button-toggle-menu-icon::before,
.CustomInputSelect .button-toggle-menu-icon::after  {
  content: '';
  border-radius: 2px;
  background: var(--color-dark-grey);
  position: absolute;
  display: block;
}

.CustomInputSelect .select-input-container:hover .button-toggle-menu-icon::before,
.CustomInputSelect .select-input-container:hover .button-toggle-menu-icon::after,
.CustomInputSelect:focus-within .select-input-container .button-toggle-menu-icon::before,
.CustomInputSelect:focus-within .select-input-container .button-toggle-menu-icon::after,
.CustomInputSelect .select-container.show .button-toggle-menu-icon::before,
.CustomInputSelect .select-container.show .button-toggle-menu-icon::after {
  background: var(--color-black);
}

.CustomInputSelect:hover,
.CustomInputSelect:focus-within {
  /*background: var(--color-light-grey);*/
}

.CustomInputSelect .button-toggle-menu-icon::before  {
  width: 9px;
  height: 3px;
  left: 0;
  top: 0vw;
}

.CustomInputSelect .button-toggle-menu-icon::after  {
  width: 3px;
  height: 9px;
  left: 0;
  top: 0;
}

.CustomInputSelect .select-options {
  display: none;
  position: absolute;
  z-index: 2;
  max-width: 300px;
  border: 1px solid #fff;
  border-radius: 0 0 3px 3px;
  margin-top: 5px;
  right: 0;
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  box-shadow: 0px 3px 3px rgba(35,31,32,0.2);
}


.CustomInputSelect .select-all {
  color: var(--color-dark-grey);
}

.CustomInputSelect .select-container.up .select-options {
  bottom: 0;
  margin-bottom: calc(35px - 1px);
  border-top: 1px solid var(--color-black);
  border-left: 1px solid var(--color-black);
  border-right: 1px solid var(--color-black);
  border-bottom: 1px solid #E0D4D0;
  border-radius: 3px 3px 0 0;
}
.CustomInputSelect .select-container.show .select-options {
  display: block;
}
.CustomInputSelect .select-option,
.CustomInputSelect .select-options-empty {
  margin: 0;
  padding: 8px 15px 8px 15px;
  min-height: 35px;
  cursor: default;
  display: flex;
  flex-direction: row;
}

.CustomInputSelect .select-option.selected {
  background: var(--color-light-grey);
}

.CustomInputSelect .select-option-checkbox {
  margin: 0 10px 0 0;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.CustomInputSelect .select-option-checkbox label {
  margin: 0
}

.CustomInputSelect .select-option-icon {
  margin: 0 15px 0 0;
  width: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CustomInputSelect .select-option-label {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}
.CustomInputSelect .select-option-secondary-label {
  color: var(--color-dark-grey);
  font-size: 0.75em;
  text-align: left;
}

.CustomInputSelect .select-option-label,
.CustomInputSelect .icon {
  margin: 0;
}
.CustomInputSelect .select-option:hover,
.CustomInputSelect .select-option:focus {
  background: #F7EEFF;
}

.CustomInputSelect .select-option:hover .CustomCheckbox input ~ .checkmark,
.CustomInputSelect .select-option:focus .CustomCheckbox input ~ .checkmark {
  border-color: var(--color-black);
}

.CustomInputSelect .select-option.disabled {
  color: var(--color-dark-grey);
}
.CustomInputSelect .select-option.disabled:hover,
.CustomInputSelect .select-option.disabled:focus {
  background: var(--color-very-light-grey) !important;
}

.CustomInputSelect .select-option.disabled:hover .CustomCheckbox input ~ .checkmark,
.CustomInputSelect .select-option.disabled:focus .CustomCheckbox input ~ .checkmark {
  border-color: var(--color-medium-grey);
}

